import Box from "../components/Atomic/Box"
import Text from "../components/Atomic/Text"
import Header from "../components/Header"

import React from "react"

export default function default404() {
  return (
    <Box>
      <Header headerText={`404`} fontSizes={[32, 36, 40, 44]} />
      <Text sx={{ color: "utilityScale.5" }}>
        Sorry page does not exit yet =(
      </Text>
    </Box>
  )
}
